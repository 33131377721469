import { TFunction } from "next-i18next";

import {
    ERROR_MESSAGES_BY_CODE_CLASSNAMES,
    ERROR_MESSAGES_BY_CODE_DEFAULT,
    ERROR_MESSAGES_BY_CODE_KEY,
} from "./error-messages-by-code";

type KeyType = keyof typeof ERROR_MESSAGES_BY_CODE_KEY;

type ConfigErrorType = Record<
    number,
    {
        message: string;
        type: "error";
        className: string;
    }
>;

type ConfigsType = {
    [key: number]:
        | {
              message: string;
              type: "error" | "warning";
              className: string;
          }
        | {
              type: "permissions";
              className: string;
          };
    [0]: {
        message: string;
        type: "error";
        className: string;
    };
};

export const generateErrorConfigs = (t: TFunction): ConfigsType => {
    const keys = Object.keys(ERROR_MESSAGES_BY_CODE_KEY).map(
        (key) => Number(key) as KeyType,
    );

    const errors = keys.reduce((acc, key) => {
        acc[key] = {
            message: t(
                ERROR_MESSAGES_BY_CODE_KEY[key],
                ERROR_MESSAGES_BY_CODE_DEFAULT[key],
            ),
            className: ERROR_MESSAGES_BY_CODE_CLASSNAMES[key],
            type: "error",
        };
        return acc;
    }, {} as ConfigErrorType);

    return {
        ...errors,
        0: {
            message: t(
                ERROR_MESSAGES_BY_CODE_KEY[0],
                ERROR_MESSAGES_BY_CODE_DEFAULT[0],
            ),
            className: ERROR_MESSAGES_BY_CODE_CLASSNAMES[0],
            type: "error",
        },
        800: {
            className: ERROR_MESSAGES_BY_CODE_CLASSNAMES[800],
            type: "permissions",
        },
    };
};
