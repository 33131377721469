const KEY_PREFIX = "common:toast";
const CLASSNAMES_PREFIX = "error-toast";

export const ERROR_MESSAGES_BY_CODE_KEY = {
    0: `${KEY_PREFIX}.somethingWentWrong`,
    500: `${KEY_PREFIX}.somethingWentWrong`,
} as const;

export const ERROR_MESSAGES_BY_CODE_DEFAULT = {
    0: "Something went wrong. Try again.",
    500: "Something went wrong. Try again.",
} as const;

export const ERROR_MESSAGES_BY_CODE_CLASSNAMES = {
    0: `${CLASSNAMES_PREFIX}-something-went-wrong`,
    500: `${CLASSNAMES_PREFIX}-something-went-wrong`,
    800: `${CLASSNAMES_PREFIX}-permissions`,
} as const;
