import { TFunction } from "next-i18next";

import { HttpErrorType } from "lib/api/types";
import { toast } from "lib/utils/toast/toast";
import { generateErrorConfigs } from "./error-configs";
import { permissionsToast } from "../toast/permissions-toast";

type Params = {
    error: HttpErrorType;
    t: TFunction;
    customErrors?: Record<
        number,
        | {
              message: string;
              type: "error" | "warning";
              className: string;
          }
        | {
              type: "permissions";
              className: string;
          }
        | { handler: () => void }
    >;
    customGlobalErrorMessage?: string;
};

export const handleServerErrors = ({
    customErrors,
    error,
    t,
    customGlobalErrorMessage,
}: Params) => {
    const handledErrors = generateErrorConfigs(t);
    const errorCode = error.error?.code;

    if (!errorCode && errorCode !== 0) {
        return;
    }

    if (customErrors?.[errorCode]) {
        const errorInfo = customErrors[errorCode];

        if ("handler" in errorInfo) {
            errorInfo.handler();
        } else {
            if (errorInfo.type === "permissions") {
                permissionsToast({
                    t,
                    message: error.error?.message,
                    options: { className: errorInfo.className },
                });
            } else {
                toast(errorInfo.message, {
                    type: errorInfo.type,
                    className: errorInfo.className,
                });
            }
        }

        return;
    }

    if (customGlobalErrorMessage) {
        const errorInfo = handledErrors[errorCode];

        if (errorInfo?.type === "permissions") {
            permissionsToast({
                t,
                message: error.error?.message,
                options: { className: errorInfo.className },
            });
        } else {
            toast(customGlobalErrorMessage, {
                type: handledErrors[0].type,
                className: handledErrors[0].className,
            });
        }

        return;
    }

    if (handledErrors[errorCode]) {
        const errorInfo = handledErrors[errorCode];

        if (errorInfo.type === "permissions") {
            permissionsToast({
                t,
                message: error.error?.message,
                options: { className: errorInfo.className },
            });
        } else {
            toast(errorInfo.message, {
                type: errorInfo.type,
                className: errorInfo.className,
            });
        }

        return;
    }

    toast(handledErrors[0].message, {
        type: handledErrors[0].type,
        className: handledErrors[0].className,
    });
};
